import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react";
import styles from "pages/team_settings/style.module.scss";
import { useTeamNameSelector } from "pages/team_settings/hooks";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetContactsListQuery } from "./endpoints";
import { columnDefinitions } from "./contactsConfig";
import { useEditContactMutation } from "components/contact-form/endpoints";
import CommonDeleteModal from "components/common-delete-modal/CommonDeleteModal";
import EmptyState from "components/empty-state/EmptyState";

export const ContactsTable = (props: {
  excluded?: string[];
  hideSearch?: boolean;
  hideCount?: boolean;
  hidePreference?: boolean;
  actions?: ReactNode;
}) => {
  const { teamName } = useTeamNameSelector();
  const [deleteContact] = useEditContactMutation();
  const { data, refetch } = useGetContactsListQuery(
    { teamName: teamName },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    const handleFocus = () => {
      refetch();
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [refetch]);

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    contentDisplay: [
      { id: "fullName", visible: true },
      { id: "jobTitle", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "notes", visible: true },
    ],
  });
  const navigate = useNavigate();
  const [showDeletedModal, setShowDeletedModal] = useState(false);

  useEffect(() => {
    setPreferences((prev) => ({
      ...prev,
      contentDisplay: prev.contentDisplay.map((item) => ({
        ...item,
        visible: !props.excluded?.includes(item.id),
      })),
    }));
  }, [props.excluded]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(data?.contacts.contacts ?? [], {
    filtering: {
      empty: (
        <EmptyState
          title="No contacts"
          action={
            <Button
              onClick={() => {
                navigate("/team/create-contact");
              }}
            >
              Create contact
            </Button>
          }
          subtitle={""}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
          subtitle={""}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "fullName" },
        isDescending: false,
      },
    },
    selection: {},
  });
  const { selectedItems } = collectionProps;

  const handleDelete = async () => {
    try {
      if (selectedItems) {
        const deletePromises = selectedItems.map((item) =>
          deleteContact({
            ...item,
            deleted: true,
          })
        );
        await Promise.all(deletePromises);
        refetch();
        navigate("/team/?tabId=contacts");
      }
    } catch (error) {
      console.error("Error deleting contacts", error);
    }
  };

  return (
    <Box>
      <Table
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  disabled={selectedItems?.length === 0}
                  onClick={() => setShowDeletedModal(true)}
                >
                  Delete
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/team/create-contact");
                  }}
                >
                  Create
                </Button>
              </SpaceBetween>
            }
            counter={
              props.hideCount ? undefined : `(${String(filteredItemsCount)})`
            }
          >
            Contacts
          </Header>
        }
        selectedItems={selectedItems}
        selectionType="multi"
        resizableColumns
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        pagination={
          props.hidePreference ? undefined : <Pagination {...paginationProps} />
        }
        filter={
          props.hideSearch ? undefined : (
            <div className={styles.filterBoxWrapper}>
              <TextFilter
                {...filterProps}
                filteringPlaceholder="Find contacts"
              />
            </div>
          )
        }
        preferences={
          props.hidePreference ? undefined : (
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              contentDisplayPreference={{
                options: columnDefinitions.map((item) => ({
                  id: item.id ?? "",
                  label: item.header as string,
                  alwaysVisible: false,
                })),
                title: "Select visible content",
              }}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 10, label: "10 contacts" },
                  { value: 20, label: "20 contacts" },
                ],
              }}
              onConfirm={({ detail }: any) => {
                setPreferences(detail);
              }}
            />
          )
        }
      />
      <CommonDeleteModal
        visible={showDeletedModal}
        setVisible={setShowDeletedModal}
        onDelete={handleDelete}
        items={selectedItems ?? []}
        title="Delete selected contacts?"
        message={
          <>
            Are you sure you want to delete the selected{" "}
            <strong>{selectedItems?.length ?? 0}</strong> contacts? This action
            cannot be undone.
          </>
        }
      />
    </Box>
  );
};

export default ContactsTable;
