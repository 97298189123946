import { z } from "zod";
import {
  CUSTOM_ATTRIBUTES_DATA_MAP_KEY_NAME,
  CustomAttributes,
  generateDynamicZodSchema,
} from "./generateCustomAttributesSchema";
import { createOptionalValidatedStringSchema, zod } from "./schemas";

/*
 * Zod Audit UI schema
 */
const auditFormSchemaForRequiredUIVisibleAttributes = z.object({
  country: zod.string(),
  entity: zod.string(),
  auditNumber: createOptionalValidatedStringSchema(),
  taxNumber: createOptionalValidatedStringSchema(),
  governmentEntity: z.string(),
  taxType: z
    .string({ required_error: "Must have at least one tax type" })
    .array()
    .nonempty({ message: "Must have at least one tax type" })
    .max(8, { message: "Cannot have more than 8 tax type" }),
  owner: zod.string(),
  auditStartDateString: zod.string(),
  auditEndDateString: zod.string(),
  auditInitiationDateString: zod.string(),
  auditCloseDateString: z.string().optional(),
  statuteOfLimitationsString: z.string().optional(),
  statusActionOwner: z.string().optional(),
  statusDetail: z.string().optional(),
  description: z.string().optional(),
  contactIds: z
    .array(z.string())
    .max(10, "You can select a maximum of 10 contacts")
    .optional(),
});

/*
 * This is the schema for the attributes, which are not shown in UI, are not part of the CreateAudit schema, but are required for audit object sent to backend
 */
const auditFormSchemaForRequiredUIInVisibleAttributes = z.object({
  auditId: zod.string(),
  auditName: zod.string(),
  teamName: zod.string(),
  status: zod.string(),
  jurisdictionLevel: z.string(),
  deleted: z.boolean(),
  auditStartDate: z.number(),
  auditEndDate: z.number(),
  auditInitiationDate: z.number(),
  auditCloseDate: z.number().optional(),
  statuteOfLimitations: z.number().optional(),
});

/*
 * This is for generating the audit dynamic schemas used for both creating/editing audit form validation, and data object schema for saving to backend
 */
export const generateAuditDynamicSchemas = (
  auditCustomAttrData: CustomAttributes
) => {
  const auditCustomAttributesSchema =
    generateDynamicZodSchema(auditCustomAttrData);

  const auditFormSchemaWithoutCrossRefinement = z.object({
    ...auditFormSchemaForRequiredUIVisibleAttributes.shape,
    [CUSTOM_ATTRIBUTES_DATA_MAP_KEY_NAME]:
      auditCustomAttributesSchema.optional(),
  });

  // This is the zod schema for creating/editing audit form both required attributes and custom attributes
  const auditFormSchema = auditFormSchemaWithoutCrossRefinement.refine(
    (data) => data.auditEndDateString > data.auditStartDateString,
    {
      message: "Audit end date should be after audit start date",
      path: ["auditEndDateString"],
    }
  );

  // This is the zod schema for the entire audit related attributes built, this is composed of four parts:
  // 1. Required attributes: invisible attributes
  // 2. Required attributes: visible attributes
  // 3. Custom attributes: visible attributes
  // 4. Custom attributes: invisible attributes (they are all string: string value pair for simplicity)
  const auditSchema = z.object({
    ...auditFormSchemaForRequiredUIInVisibleAttributes.shape,
    ...auditFormSchemaWithoutCrossRefinement.shape,
    [CUSTOM_ATTRIBUTES_DATA_MAP_KEY_NAME]: z
      .record(zod.string(), zod.string())
      .optional(),
  });

  return {
    auditSchema,
    auditFormSchema,
  };
};

const basicAuditFormSchemaWithoutCrossRefinement = z.object({
  ...auditFormSchemaForRequiredUIVisibleAttributes.shape,
});

export const basicAuditFormSchema =
  basicAuditFormSchemaWithoutCrossRefinement.refine(
    (data) => data.auditEndDateString > data.auditStartDateString,
    {
      message: "Audit end date should be after audit start date",
      path: ["auditEndDateString"],
    }
  );

export const basicAuditSchema = z.object({
  ...auditFormSchemaForRequiredUIInVisibleAttributes.shape,
  ...basicAuditFormSchemaWithoutCrossRefinement.shape,
});

export type Audit = z.infer<typeof basicAuditSchema>;
