import { mainApi } from "services/main";
import {
  IContactRequest,
  IContactResponse,
  IEditContactRequest,
  IEditContactResponse,
  IGetContactRequest,
  IGetContactResponse,
  IGetAuditsByContactIdRequest,
  IGetAuditsByContactIdResponse,
  IGetContactsByAuditIdRequest,
  IGetContactsByAuditIdResponse,
  IAuditContactAssociationResponse,
  IAuditContactAssociationRequest,
} from "./definitions";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation<IContactResponse, IContactRequest>({
      query: (request: IContactRequest) => ({
        url: "contact",
        body: request,
        method: "POST",
      }),
    }),
    editContact: builder.mutation<IEditContactResponse, IEditContactRequest>({
      query: (request: IEditContactRequest) => ({
        url: `/contact/${request.contactId}`,
        body: request,
        method: "PUT",
      }),
    }),
    getContact: builder.query<IGetContactResponse, IGetContactRequest>({
      query: (request: IGetContactRequest) => ({
        url: `/contact/${request.contactId}`,
        params: { teamName: request.teamName },
      }),
    }),
    getAuditsByContactId: builder.query<
      IGetAuditsByContactIdResponse,
      IGetAuditsByContactIdRequest
    >({
      query: (request) => ({
        url: `/contact-audits/${request.contactId}/audits`,
      }),
    }),
    getContactsByAuditId: builder.query<
      IGetContactsByAuditIdResponse,
      IGetContactsByAuditIdRequest
    >({
      query: (request) => ({
        url: `/audit-contacts/${request.auditId}/contacts`,
      }),
    }),
    auditContactAssociation: builder.mutation<
      IAuditContactAssociationResponse,
      IAuditContactAssociationRequest
    >({
      query: (request: IAuditContactAssociationRequest) => ({
        url: `/contact-associations/audit/${request.auditId}`,
        method: "POST",
        body: request,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateContactMutation,
  useGetContactQuery,
  useGetAuditsByContactIdQuery,
  useGetContactsByAuditIdQuery,
  useAuditContactAssociationMutation,
  useEditContactMutation,
  usePrefetch,
} = extendedApi;
