import { Box, TableProps } from "@amzn/awsui-components-react";
import { Link } from "react-router-dom";
import { IContact } from "./definitions";
import { createLabelFunction } from "utils/table-utils";

export const columnDefinitions: TableProps.ColumnDefinition<IContact>[] = [
  {
    id: "fullName",
    header: "Name",
    cell: (item) => {
      return (
        <Link
          to={`/team/contact-information/${item.contactId}`}
          style={{ color: "#006ce0" }}
        >
          {item.fullName}
        </Link>
      );
    },
    isRowHeader: true,
    ariaLabel: createLabelFunction("fullName"),
    sortingField: "fullName",
  },
  {
    id: "jobTitle",
    header: "Title",
    cell: (item) => {
      return <Box>{item.jobTitle}</Box>;
    },
    ariaLabel: createLabelFunction("jobTitle"),
    sortingField: "jobTitle",
  },
  {
    id: "email",
    header: "Email",
    cell: (item) => {
      return <Box>{item.email}</Box>;
    },
    ariaLabel: createLabelFunction("email"),
    sortingField: "email",
  },
  {
    id: "phone",
    header: "Phone",
    cell: (item) => {
      return <Box>{item.phoneNumber}</Box>;
    },
    ariaLabel: createLabelFunction("Phone"),
    sortingField: "phone",
  },
  {
    id: "notes",
    header: "Notes",
    cell: (item) => {
      return <Box>{item.notes}</Box>;
    },
    ariaLabel: createLabelFunction("Notes"),
    sortingField: "notes",
  },
];
